import {useContext, useState, useEffect} from 'react'
import {AppContext, getAppContextInitialState} from '../contexts/AppContext'
import {PackageSelectorContext} from '../contexts/PackageSelectorContext'
import {UserContext} from '../contexts/UserContext'
import {
  resetAllButNations,
  setErrors as setPsErrors,
} from '../contexts/PackageSelectorActions'
import useApi from './useApi'
import {
  EMPTY_INPS_VALUE,
  participationsLookup,
} from '../main/specificUtils/configs'
import useAuthentication from './useAuthentication'
import {SpendingContext} from 'contexts/SpendingContext'

export default function useContextController() {
  const [insertedDataFromUser, setInsertedDataFromUser] = useState(false)
  const [state, setState] = useContext(AppContext)
  const [psState, psDispatch] = useContext(PackageSelectorContext)
  const [spendingState] = useContext(SpendingContext)
  const [userState] = useContext(UserContext)
  const api = useApi()
  const {getUserData, checkIsUserLogged} = useAuthentication()
  const isUserLogged = checkIsUserLogged()

  function setPkgSelectorErrors(externalErrors) {
    const rawErrors = externalErrors || {}
    const {
      step1Catalog: catalog,
      step1Nation: nation,
      step1PackageGroup: webGroup,
      step1Package,
      step1Turn,
      step1DepartureServiceItems,
      step1DepartureServiceIds,
      step1Structure: structure,
      step1ProgramTypology: programTypology,
    } = rawErrors
    const stepPackage = step1Package || step1Turn
    const departureService =
      step1DepartureServiceItems || step1DepartureServiceIds

    psDispatch(
      setPsErrors({
        catalog,
        nation,
        webGroup,
        package: stepPackage,
        departureService,
        structure,
        programTypology,
      })
    )
  }

  useEffect(() => {
    // console.log('effect triggered, ', {
    //   api,
    //   getUserData,
    //   insertedDataFromUser,
    //   isUserLogged,
    //   setState,
    // })
    function isValidDate(d) {
      return d instanceof Date && !isNaN(d)
    }
    async function updateFromUserData() {
      const userData = getUserData()
      let birthDate = new Date(userData.birthDate)
      let step1ParentBirthCity = null
      let step1ParentBirthProv = ''
      let step1ParentResidenceCity = ''
      let step1ParentResidenceCityObj = null
      let step1ParentProv = ''
      let step1ParentRegion = ''

      if (userData.birthPlace) {
        const birthPlaceComuni = await api.getComuniExactByName(
          userData.birthPlace
        )
        if (birthPlaceComuni.length > 0) {
          step1ParentBirthCity = birthPlaceComuni[0]
          step1ParentBirthProv = birthPlaceComuni[0].car
        }
      }

      if (userData.city) {
        const residenceComuni = await api.getComuniExactByName(userData.city)
        if (residenceComuni.length > 0) {
          step1ParentResidenceCityObj = residenceComuni[0]
          step1ParentResidenceCity = step1ParentResidenceCityObj.name
          step1ParentProv = step1ParentResidenceCityObj.car
          step1ParentRegion = step1ParentResidenceCityObj.region
        }
      }

      if (!isValidDate(birthDate)) {
        console.log('invalid date', {birthDate, date: userData.birthDate})
        birthDate = null
      }
      setState(state => ({
        ...state,
        step1ParentName: userData.firstName,
        step1ParentSurname: userData.lastName,
        step1ParentEmail: userData.email1,
        step1ParentBirthDate: birthDate,
        step1ParentCAP: userData.cap || '', //
        step1ParentFiscalCode: userData.fiscalCode,
        step1ParentBirthCity,
        step1ParentBirthProv,
        step1ParentBirthNation: userData.birthNation || '',
        step1ParentNationality:
          userData.foreignBirthCity != null ? 'Estera' : 'Italiana',
        step1ParentGender: userData.gender === 'F' ? 'F' : 'M',
        step1ParentStreetAddress: userData.address || '', //
        step1ParentResidenceCityObj,
        step1ParentResidenceCity,
        step1ParentProv, //
        step1ParentRegion,
        step1ParentPhone: userData.mobilePhone || '',
        // step1ParentPhoneAlt: userData.phone1 || '', //
        step4Privacy: !!userData.privacyAccepted,
        step4Marketing: userData.marketingAccepted ? 'si' : 'no',
        step4Profilation: userData.profilationAccepted ? 'si' : 'no',
        step4Profilation2: userData.profilation2Accepted ? 'si' : 'no',
      }))
    }
    if (isUserLogged && !insertedDataFromUser) {
      setInsertedDataFromUser(true)
      updateFromUserData()
    } else if (!isUserLogged && insertedDataFromUser) {
      setInsertedDataFromUser(false)
      setState(state => ({
        ...state,
        step1ParentName: '',
        step1ParentSurname: '',
        step1ParentEmail: '',
        step1ParentBirthDate: null,
        step1ParentCAP: '',
        step1ParentFiscalCode: '',
        step1ParentBirthCity: null,
        step1ParentBirthNation: '',
        step1ParentNationality: 'Italiana',
        step1ParentGender: '',
        step1ParentStreetAddress: '',
        step1ParentResidenceCity: '',
        step1ParentProv: '',
        step1ParentPhone: '',
        step1ParentPhoneAlt: '',
        step4Privacy: false,
        step4Marketing: '',
        step4Profilation: '',
        step4Profilation2: '',
      }))
    }
  }, [api, getUserData, insertedDataFromUser, isUserLogged, setState])

  // function handleGenericError(errorActionMsg = 'generico') {
  //   alert(
  //     `Si è verificato un errore ${errorActionMsg}. Si prega di riprovare più tardi o contatta uno dei seguenti numeri: 0736343440 oppure 0736336339.`
  //   )
  // }

  async function loadBonus() {
    const bonus = await api.getAlternativeServices(
      psState.package,
      'bonus',
      state.division
    )
    await setState(state => ({...state, step2bonuses: bonus}))
  }

  async function loadInps() {
    if (psState.package) {
      const inpsServices = await api.getAlternativeServices(
        psState.package,
        'contributoinps',
        state.division
      )

      const bonus = await api.getAlternativeServices(
        psState.package,
        'bonus',
        state.division
      )
      setState(state => ({
        ...state,
        inpsServices: inpsServices,
        step2bonuses: bonus,
      }))
      return inpsServices
    } else {
      alert('Selezionare prima il programma ed il turno!')
      return null;
    }
  }

  async function registerIntent(expectedPracticeId = false) {
    setState(state => ({...state, isSubmitting: true}))
    const mappedPsState = mapPsStateToOldState()
    const {token} = userState
    const query = await api.registerIntent(
      {
        ...state,
        ...mappedPsState,
        clubRegistration: !isUserLogged && state.memberExists,
      },
      token
    )
    if (query.result && state.id == null && query.data.id) {
      setState(state => ({
        ...state,
        id: query.data.id,
        authCode: query.data.authCode,
        isSubmitting: false,
        packageId: query.data
          ? query.data.step1Package
          : null,
      }))
    } else if (!query.result) {
      setState(state => ({...state, errors: query.data, isSubmitting: false}))
      setPkgSelectorErrors(query.data)
    } else if (expectedPracticeId) {
      setState(state => ({
        ...state,
        isSubmitting: false,
        practiceId: query.data.id,
      }))
    } else {
      setState(state => ({...state, isSubmitting: false}))
    }
    return query
  }

  function resetState() {
    setState(state => ({
      ...getAppContextInitialState({
        division: state.division,
        type: state.applicationType,
      }),
    }))
    psDispatch(resetAllButNations())
  }

  function resetAllParticipantsRelated() {
    setState(state => ({
      ...state,
      inpsServices: [],
      step2bonuses: [],
      step2Grade: null,
      step2InpsServiceId: '',
      step2BonusSurname: '',
      step2BonusName: '',
      step2WelfareType: '',
      step2AziendaConvenzionataNote: '',
      step2BonusType: '',
      step2BonusId: null,
      step2InpsContributeQuantity: '',
      // step2Participation: '',
      step2BonusRequested: null,
    }))
  }

  function validateADACustomerData() {
    const {step1Questions, step1IceFullName, step1IceCellPhone, step2Participation} = state

      if (step1IceFullName.trim() === ''){
      setState(state => ({...state, errors: {step1IceFullName: 'Campo obbligatorio'}}))
      return false;
    }

    if (step1IceCellPhone.trim() === ''){
      setState(state => ({...state, errors: {step1IceCellPhone: 'Campo obbligatorio'}}))
      return false;
    }

    if (step1Questions.length === 0 && (step2Participation === participationsLookup.INPSIEME || step2Participation === participationsLookup.INPSNUOVO)){
      setState(state => ({...state, errors: {step1Questions: 'Rispondi alla domanda INPS'}}))
      return false;
    }

    return true
  }

  function validateADAStep3Data() {
    const {step3DisabilitiesService, step3HasAdditionalNeeds, step3AdditionalNeeds, step3Handicap} = state

    if (step3Handicap === 'si' && step3DisabilitiesService.trim() === ''){
      setState(state => ({...state, errors: {step3DisabilitiesService: 'Campo obbligatorio'}}))
      return false;
    }

    //Se ha selezionato che ha bisogno di esigenze particolari ma non ha scritto nulla, errore
    if (step3HasAdditionalNeeds === 'si' && step3AdditionalNeeds.trim() === ''){
      setState(state => ({...state, errors: {step3AdditionalNeeds: 'Inserisci le esigenze o clicca su No se non hai nessuna richiesta particolare'}}))
      return false;
    }

    return true
  }


  function validateVacationCustomerData() {
    const {
      step1Questions,
      step1ChildDocType,
      step1ChildDocNumber,
      step1ChildDocEmission,
      step1ChildDocExpiration,
      step2Participation,
      applicationType,
      step1ParentEmail,
      step1ParentPhone,
    } = state

    // console.info("applicationType", applicationType);
    const isMain = applicationType === 'main';
    const isSpecificForm = ((applicationType !== 'main') && (applicationType !== 'hsp')) && (applicationType !== 'docenti') && (applicationType !== 'lin');

    // Se sono nel form main, la domanda INPS 2023 dello step1 è obbligatoria. Richiesta del 04/03/2024
    if (isMain && step1Questions.length === 0 && (step2Participation === participationsLookup.INPSIEME || step2Participation === participationsLookup.INPSNUOVO)){
      setState(state => ({...state, errors: {step1Questions: 'Rispondi alla domanda INPS'}}))
      return false;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(step1ParentEmail)) {
      setState(state => ({...state, errors: {step1ParentEmail: 'Inserisci un\'email corretta'}}))
      return false
    }
    if (!/^(\+?[1-9][0-9]*)$/.test(step1ParentPhone)) {
      setState(state => ({...state, errors: {step1ParentPhone: 'Attenzione, il numero non deve contenere 0 iniziale, spazi bianchi e/o altri caratteri speciali'}}))
      return false
    }
    if(!step2Participation){
      setState(state => ({...state, errors: {step2Participation: 'richiesto'}}))
      return false
    }
    if (!step1ChildDocType && isSpecificForm) { //Se sono nel form main o hsp o docenti, il documento non dev'essere obbligatorio
      setState(state => ({...state, errors: {step1ChildDocType: 'richiesto'}}))
      return false
    }
    if (step1ChildDocType === 'idCard') {
      const isValid = /^\w{2}\d{5}(\d{2}|\w{2})$/.test(step1ChildDocNumber)
      if (!isValid) {
        setState(state => ({
          ...state,
          errors: {
            step1ChildDocNumber:
              'Numero documento non valido: deve contenere due lettere e sette numeri, oppure due lettere, cinque numeri e due lettere',
          },
        }))
        return false
      }
    } else if (step1ChildDocType === 'passport') {
      const isValid = /^\w{2}\d{7}$/.test(step1ChildDocNumber)
      if (!isValid) {
        setState(state => ({
          ...state,
          errors: {
            step1ChildDocNumber:
              'Numero passaporto non valido: deve contenere due lettere e sette numeri',
          },
        }))
        return false
      }
    }
    if (!step1ChildDocEmission && isSpecificForm) {//Se sono nel form main o hsp, il documento non dev'essere obbligatorio
      setState(state => ({
        ...state,
        errors: {
          step1ChildDocEmission: ' richiesto',
        },
      }))
      return false
    } else if (!step1ChildDocExpiration && isSpecificForm) {//Se sono nel form main o hsp, il documento non dev'essere obbligatorio
      setState(state => ({
        ...state,
        errors: {
          step1ChildDocExpiration: ' richiesto',
        },
      }))
      return false
    } else if (step1ChildDocEmission > step1ChildDocExpiration) {
      setState(state => ({
        ...state,
        errors: {
          step1ChildDocExpiration:
            'La data di scadenza non può essere antecedente alla data di emissione documento',
        },
      }))
      return false
    }
    return true
  }

  function validateStepInformation() {
    const {step3CodiceDocente} = state
    if (!step3CodiceDocente) {
      setState(state => ({...state, errors: {step3CodiceDocente: 'Il codice docente è obbligatorio'}}))
      return false
    }

    return true
  }

  function validateExtraServices() {
    const {extraServices, step4ExtraServices} = state
    if (!Array.isArray(extraServices) || extraServices.length === 0) {
      return true
    }

    //Resetto tutti i valori di errore
    extraServices.forEach(x => x.mustSelectAtLeastOneItem = false);


    const extraServicesNotSelected = extraServices.filter((obj) => {
      // Controlla se almeno uno degli oggetti in extraservices ha un value presente in nuovoArray
      return !obj.extraservices.some((es) => step4ExtraServices.includes(es.value));
    });

    //Scorro tutti gli extraService non selezionati per impostare il flag che si occuperà di mostrare l'errore
    //Setto l'errore solo per quegli extraservices che non sono opzionali
    extraServicesNotSelected.forEach(x => {
      if (!x.optional) {
        x.mustSelectAtLeastOneItem = true;
      }
    });

    const isValid = extraServices.filter(x => !x.optional)
      .map(bunch => bunch.extraservices.map(({value}) => value))
      .every(bunch => bunch.some(es => step4ExtraServices.includes(es)))
    return isValid
  }


  //Aggiornamento 28/7/23. Metodo non utilizzato perché il codice non dev'essere obbligatorio. Lascio la funzione perché potrebbe servire
  function validateStep2InpsHsp() {
    const {
      step2Participation,
      variablePracticeInpsNumber,
    } = state

    //Se è hsp, il controllo è diverso. Dev'essere numeri e lettere e di 15 caratteri

    const isCorrectParticipation = (step2Participation === participationsLookup.INPSIEME || step2Participation === participationsLookup.INPSNUOVO);
    let isValid = true;
    //Valuto solo se è partecipazione inps
    if (isCorrectParticipation) {
      isValid = (variablePracticeInpsNumber.trim() === "") || (/^[a-zA-Z0-9]{15}$/.test(variablePracticeInpsNumber));
    }
    if (!isValid) {
      setState(state => ({
        ...state,
        errors: {
          variablePracticeInpsNumber: true,
        },
      }))
    }
    return isValid
  }

  function validateStep2Inps() {
    const {
      division,
      step2InpsServiceId,
      step2Participation,
      step2Class,
      variablePracticeInpsNumber,
      //step2INPSnewQuestion, @agata flusso 4 post graduatorie 2022
      inpsServices,
    } = state

    let step2InpsServiceFlag = state.step2InpsServiceFlag;

    if (
      (step2Participation !== participationsLookup.INPSIEME &&
        step2Participation !== participationsLookup.INPSNUOVO) ||
      step2InpsServiceId === EMPTY_INPS_VALUE
    ) {
      return true
    }
    const shouldConfirmInpsValue =
      (inpsServices.find(s => `${s.value}` === `${step2InpsServiceId}`) || {})
        .code !== '2S-NNCOM'

    const isInps = (step2Participation === participationsLookup.INPSIEME || step2Participation === participationsLookup.INPSNUOVO)
    const isNewInps = step2Participation === participationsLookup.INPSNUOVO

    const validClass = division !== '1' || isNewInps === false || !!step2Class

    //Se il flusso è inps, è stato inserito il codice della pratica ed è un testo, c'è errore
    // const variablePracticeInpsNumberFlag = (participationsLookup.INPSNUOVO || participationsLookup.INPSIEME) && (variablePracticeInpsNumber.trim() !== "") && (isNaN(variablePracticeInpsNumber) || (variablePracticeInpsNumber.length !== 15 && variablePracticeInpsNumber.length > 0));
    //Modifica richiesta il 14 marzo 2024. Se il flusso è INPS e il campo è vuoto, è errore (questo perché dev'essere obbligatorio
    const isVariablePracticeInpsNumberMandatory = isInps && ((variablePracticeInpsNumber.trim() === "") || !(/^[a-zA-Z0-9]{15}$/.test(variablePracticeInpsNumber)));

    /* @agata flusso 4 post graduatorie 2022
    const validNewQuestion =
      division !== '1' || isNewInps === false || !!step2INPSnewQuestion
    */
    if (state.applicationType === 'main') {
      //Se siamo sul form main
      // rimosso il controllo su step2InpsServiceFlag poiché non è più necessario che la dichiarazione che l'importo del contributo
      //comunicato corrisponda a quello a cui l'utente ha diritto, sia obbligatoria
      step2InpsServiceFlag = true;
    }
    let isValid =
      /*step2InpsServiceId &&*/ //Commentato perché non è più richiesto che sia obbligatorio. Modifica richiesta il 14 marzo 2024
      (!shouldConfirmInpsValue || step2InpsServiceFlag) &&
      validClass && !isVariablePracticeInpsNumberMandatory
      /* @agata flusso 4 post graduatorie 2022
      validClass &&
      validNewQuestion
      */
    if (!isValid) {
      setState(state => ({
        ...state,
        errors: {
          step2Class: !validClass,
          // step2InpsServiceId: !step2InpsServiceId, //Commentato perché non è più richiesto che sia obbligatorio. Modifica richiesta il 14 marzo 2024
          step2InpsServiceFlag: !step2InpsServiceFlag,
          variablePracticeInpsNumber: isVariablePracticeInpsNumberMandatory,
          //step2INPSnewQuestion: !validNewQuestion, @agata flusso 4 post graduatorie 2022
        },
      }))
    }
    return isValid
  }

  //20250129 aggiunta obbligatorietà età e classe frequentata
  function validateStep2Class() {
    const {
      step2Class,
      step2ParticipantAge,
      step2Participation,
    } = state

    const isValidParticipation = step2Participation === participationsLookup.INPSIEME || step2Participation === participationsLookup.INPSNUOVO;
    let validStep2Class = true;
    if (state.applicationType === 'main' && isValidParticipation) {
      validStep2Class = !!step2ParticipantAge && !!step2Class;
    }
    let isValid = validStep2Class;
    if (!isValid) {
      setState(state => ({
        ...state,
        errors: {
          validStep2Class: !validStep2Class,
        },
      }))
    }
    return isValid
  }

  function validateStepDisability() {
    const {
      step3Handicap,
      step3DisabilitiesType,
      step3DisabilitiesHelperType,
      step3DisabilitiesHelper,
    } = state

    if (step3Handicap === 'no') {
      return true
    }

    if (step3DisabilitiesType === 'comma1') {
      return true
    }

    const isValid = !!step3DisabilitiesHelperType && !!step3DisabilitiesHelper

    if (!isValid) {
      setState(state => ({
        ...state,
        errors: {
          step3DisabilitiesType: !step3DisabilitiesType,
          step3DisabilitiesHelperType: !step3DisabilitiesHelperType,
          step3DisabilitiesHelper: !step3DisabilitiesHelper,
        },
      }))
    }

    return isValid
  }

  function mapPsStateToOldState() {
    return {
      step1Catalog: psState.catalog,
      step1Nation: psState.nation,
      step1PackageGroup: psState.webGroup,
      step1Package: psState.package,
      step1Turn: psState.package,
      step1DepartureServiceItems: psState.departureService,
      step1DepartureServiceIds: psState.departureService,
      step1Structure: psState.structure,
      step1ProgramTypology: psState.programTypology,
      step1HasStructure: !!psState.structures && psState.structures.length > 0,
    }
  }

  return {
    loadBonus,
    loadInps,
    registerIntent,
    resetState,
    resetAllParticipantsRelated,
    validateVacationCustomerData,
    validateStepInformation,
    validateExtraServices,
    validateStep2Inps,
    validateStep2InpsHsp,
    validateStepDisability,
    validateADACustomerData,
    validateADAStep3Data,
    validateStep2Class,
  }
}
